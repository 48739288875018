/* general
-------------------------------------------------------- 
	
*/
html,body{margin:0;padding:0;}

html{background:#fff;}
body{background:#fff;}

body{font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;color:#333;font-size:14px;}

/*h1{text-transform:uppercase;}
h1,h2{border-bottom:1px solid #bdbdbd;}*/

img {border:0;}


/* html5 elements
-------------------------------------------------------- 

*/
header,footer,nav,article,section,aside,figure,figcaption{display:block;}


/* page structure
-------------------------------------------------------- 

	Layout and size of header, content and footer
	
*/
/* the header with related links navigation */
header#above{font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;border-bottom: 1px solid #e5e5e5;border-bottom:1px solid #E6E6E6;margin-bottom:10px;color:#000;}

/* set width and center page */
header#top,
div#wrapper,
footer#bottom{margin:0 auto;width:800px;}

/* set width of header top */
header#top,
footer#bottom{margin:0 auto;width:800px;}

/* set width of footer bottom */
div#footer{margin:0 auto;width:800px;}

/* add common padding to main content block */
div#wrapper{padding:60px 0 20px 0;height: auto;}

/* add padding and background to the footer block */
div#footer{padding-bottom:20px;background:fff;padding-left:5px;clear: both;border-top: 1px solid #e5e5e5;border-top:1px solid #E6E6E6;margin-top:10px;}


/* a links
-------------------------------------------------------- 

*/
a {	color: inherit; outline:0;}
a:link    { }
a:visited { }
a:hover   { text-decoration:none; color:#5C0A0A; }
a:active  { }


/* navigation menu
-------------------------------------------------------- 

*/

nav.navmenu {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	margin-top: 36px;
}
nav.navmenu a {
	color: #747474 /*#428bca*/;
	border-radius:4px;
	position: relative;
	padding: 10px 15px;
	text-decoration: none;
}

nav.navmenu a:hover {
	text-decoration: none;
	background-color: #eee;
	border-radius:4px;
	position: relative;
	padding: 10px 15px;
	color: #000;
}

nav.navmenu a.active {
	color: #fff;
	background-color: #000 /*#428bca*/; /*AACD4B B5D954*/
	border-radius:4px;
	position: relative;
	padding: 10px 15px;
	text-decoration: none;
}

/* figure & figcaption
-------------------------------------------------------- 

*/
figure {border:1px solid #E6E6E6;padding:4px;margin:1em;}
figcaption {font-style:italic;font-size:smaller;padding-left:1em;}

figure.right{margin-right:0;}
figure.top{margin-top:0;}
figure.fullwidth{margin-left:0;margin-right:0;}

/* Misc classes
-------------------------------------------------------- 

*/
.right {float:right !important;}
.left {float:left;}


/* clearer fix */
div#wrapper, div#classroom, .navmenu::after {overflow:auto;}

/* article byline & signature
-------------------------------------------------------- 

*/
footer.byline {
    clear: both;
	border-top: 1px dashed #999999;
	margin-top:2em;
	padding: 1em;
	color: #555555;
	font-size: smaller;
	font-style:italic;
}

footer.byline img {padding:2px;outline:#999999 groove 1px;
}


/* page footer
-------------------------------------------------------- 

*/
footer#bottom {
	font: italic 0.8em Arial, Helvetica, sans-serif;
	color: #4D4D4D;
}


/* additional style
-------------------------------------------------------- 

*/

/* wrapper */
div#wrapper {
	padding-left: 40px;
	padding-right: 40px;
	border-radius:6px;
	background-color: #eee;
	margin-top: 20px;
	margin-bottom: 20px;
}

/* header */
header#top {
	border-bottom: 1px solid #e5e5e5;
	margin-bottom:4px;
	padding-right: 0;
	padding-left: 0;
}

/* table div style
-------------------------------------------------------- 

*/

div.col {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

div.card-header {
    text-align: left;
}

.content {
    position: absolute;
    width: 100%;
    background-color: white !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

div.classroomTable {
	padding-bottom : 100%; /* = width for a 1:1 aspect ratio */
	border-radius: 4px;
	background-color: #fff;
	border-color: #fff;
	border:1px solid #eee;
	cursor: pointer;
	text-align: center;
	}

    div.classroomTable-selected {
        padding-bottom : 100%; /* = width for a 1:1 aspect ratio */
        border-radius: 4px;
        background-color: #936A4A;
        background: url(../img/table.png);
        border-color: #fff;
        border:1px solid #000;
        cursor: pointer;
        text-align:center;
    }

div.classroomTable-selected p {	
 	color: #333;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
	}
	
div.classroomTable:hover {
	padding: 5 5 5 5;
	border-radius: 4px;
	background-color: #F7F7F7;
	margin: 5 5 5 5;
	border:1px solid #000;
	cursor: pointer;
	}

div.classroomTable-selected:hover {
	padding: 5 5 5 5;
	border-radius: 4px;
	background-color: #9A806C;
	margin: 5 5 5 5;
	border-color: #fff;
	border:1px solid #000;
	cursor: pointer;
	}

p.student {

background-color: white;

  vertical-align: middle;
   	
}

div#textdiv {
	width:371px;
	margin-right: auto;
	margin-left: auto;
}


/* fix for btn size bug in jumbotron
-------------------------------------------------------- 

*/
.btn-xl {
	border-radius: 6px !important;
    font-size: 18px !important;
    line-height: 1.33333 !important;
    padding: 10px 16px !important;
	}

.btn-sm {
	border-radius: 3px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    padding: 5px 10px !important;
	}
.btn-xs {
	border-radius: 3px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    padding: 1px 5px !important;
	}

/* panel adjustment
-------------------------------------------------------- 

*/

.panel-extra {
	background-color: #428bca !important;
    border-color: #357ebd !important;
	/* green background-color: #5cb85c !important;
    border-color: #4cae4c !important;*/
    color: #fff !important;
	cursor: pointer !important;
	border: 1px solid transparent !important;
	text-decoration: none !important;
	font-weight: 400 !important;
	} 

.panel-extra:hover {
	/*background-color: #47A447 !important;
	border-color: #398439 !important;*/
	text-decoration: none !important;
	background-color: #3276b1 !important;
	border-color: #285e8e !important;
	}

.panel-extra a:hover,
.panel-extra a:focus,
.panel-extra a:active {
	text-decoration: none;
	}

.panel-extra h4 {
	font-weight: 400;
	}

/* the header with related links navigation */
header#above{font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;border-bottom: 1px solid #e5e5e5;border-bottom:1px solid #E6E6E6;padding-bottom:10px;padding-top:10px;color:#000;text-align:center;font-weight: 200;background-color:#F2DEDE;vertical-align:middle;}

.popover-title {
	background-color: #d43f3a !important;
    border-bottom: 1px solid white important;
	}

.popover {
	background-color: #d9534f !important;
	color: white !important;
	}
	
.popover.right > .arrow:after {
	border-right-color: #d9534f !important;
	}

.popover.left > .arrow:after {
	border-left-color: #d9534f !important;
	}
	
.popover.bottom > .arrow:after {
	border-bottom-color: #d9534f !important;
	}

.popover-navigation button{
	background-color: #d43f3a !important;
	}

body {
    padding-top: 0px !important;
}

.container {
	padding-top: 20px !important;
}