@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');
.card-header
{
    background-color: #428bca !important;
    border-color: #357ebd !important;
    color: #fff !important;
    cursor: pointer !important;
    border: 1px solid transparent !important;
    text-decoration: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.jumbotron h1
{
    font-size: 63px;
}

.card-header:hover
{
    text-decoration: none !important;
    background-color: #3276b1 !important;
    border-color: #285e8e !important;
}

.nav-pills .nav-item a.nav-link.active
{
    color: #fff !important;
    background-color: #428bca !important;
}

.nav-pills .nav-item a, .nav-pills .nav-item a.nav-link.active
{
    font-size: 16px !important;
}

.nav-pills .nav-item a
{
    position: relative;
    display: block;
    padding: 10px 15px !important;
    color: #428bca !important;
    text-decoration: none !important;
    margin-right: 0.125em !important;
    margin-left: 0.125em !important;
}

.nav-pills .nav-item a:hover
{
    background-color: #eee !important;
    color: #2a6496 !important;
}

.accordion .card
{
    margin-bottom: 20px !important;
    border-radius: 4px !important;
}

.seating-control.col-auto, .grouping-control.col-auto
{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 7.5px !important;
    margin-right: 7.5px !important;
}

.seating-control.btn
{
    margin-left: 7.5px !important;
    margin-right: 7.5px !important;
}

.card-body h2
{
    margin-bottom: 1.25rem !important;
}

.container.no-top-padding
{
    padding-top: 0px !important;
}

#groups table
{
    margin: auto !important;
}

#groups .group
{
    min-height: 20px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.btn-success
{
    color: #fff !important;
    background-color: #5cb85c !important;
    border-color: #4cae4c !important;
}

.btn-success:hover
{
    color: #fff !important;
    background-color: #47a447 !important;
    border-color: #398439 !important;
}

.btn-primary
{
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
}

.btn-primary:hover
{
    color: #fff;
    background-color: #3276b1;
    border-color: #285e8e;
}

.btn
{
    font-weight: 500 !important;
}

.btn:active
{
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}

.btn.popup
{
    position: absolute;
    right: 20px;
    top: 20px;
}

.btn-xxs
{
    border-radius: 3px !important;
    font-size: 10px !important;
    line-height: 1.5 !important;
    padding: 1px 5px !important;
}

.randomized-student
{
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #999;
}

#selectedStudentWithoutReplacement, #selectedStudentWithReplacement
{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#selectedStudentWithoutReplacementModal, #selectedStudentWithReplacementModal
{
    margin: 4rem 0;
}

#selectedStudentWithoutReplacementModal span, #selectedStudentWithReplacementModal span
{
    font-size: 5em !important;
    font-weight: 700;
}

#selectedStudentWithoutReplacement span, #selectedStudentWithReplacement span
{
    font-size: 1rem;
}

.modal-header, #selectedStudentWithoutReplacementModal, #selectedStudentWithReplacementModal, #previouslySelectedStudentsModal
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body
{
    text-align: center;
}

.modal-body button
{
    margin-bottom: 1em;
}

#students
{
    width: 50%;
    display: block;
    margin: auto;   
}